import React from 'react'
import { Widget } from '@typeform/embed-react'

const WorkWithUsForm = () => {
  return (
    <section className="contact-area ptb-100">
      <div className="row justify-content-center">
        <div className="col-md-8 col-11">
          <Widget id={`eSByam`} style={{ height: '500px' }} />
        </div>
      </div>
      
    </section>
  )
}

export default WorkWithUsForm

import React from 'react'
import Layout from '../components/App/Layout'
import Navbar from '../components/Common/Navbar'
import PageBanner from '../components/Common/PageBanner'
import Footer from '../components/Common/Footer'
import WorkWithUsForm from '../components/WorkWithUs/WorkWithUsForm'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  {
    strapiSeos(slug: { eq: "work-with-us" }) {
      Meta {
        title
        description
        keywords
      }
      TwitterMeta {
        card
      }
    }
  }
`

const WorkWithUs = () => {
  const data = useStaticQuery(query)
  const {
    strapiSeos: { Meta: metaTags, TwitterMeta },
  } = data

  return (
    <Layout metaTags={metaTags} twitterCard={TwitterMeta}>
      <Navbar />

      <PageBanner
        pageTitle="Work With Us"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Work With Us"
      />

      <WorkWithUsForm/>

      <Footer />
    </Layout>
  )
}



export default WorkWithUs
